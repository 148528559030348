






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FormView from "@/components/FormView.vue";
import { namespace } from "vuex-class";
import Avatar from "@/components/Avatars/Avatar.vue";
import { formatInstructorName } from "@/utils/NameUtil";

const RoleModule = namespace("role");

@Component({
  components: { FormView, Avatar },
})
export default class RoleView extends Vue {
  public name = "RoleView";

  @RoleModule.Action("findOne")
  public findRoleById: any;

  @RoleModule.Getter("getDataItem")
  public role: any;

  @Prop()
  public selectedRole!: any;

  public users: any = [];

  public async mounted(): Promise<void> {
    if (this.selectedRole) {
      await this.findRoleById({
        id: this.selectedRole.id,
        resource: "roles",
      });
      this.users = this.role.users.sort((a: any, b: any) => (a.firstName < b.firstName ? -1 : a.firstName == b.firstName ? 0 : 1));
    }
  }

  public closeView(): void {
    this.$emit("close");
  }

  public get roleTypeLabel(): string {
    return this.role ? this.role.description : "";
  }

  public instructorName(instructor: any) {
    return instructor ? formatInstructorName(instructor.firstName, instructor.lastName) : "";
  }

  @Watch("selectedRole")
  public async onSelectedRoleChange(role: any): Promise<void> {
    if (role) {
      await this.findRoleById({
        id: role.id,
        resource: "roles",
      });
      this.users = this.role.users.sort((a: any, b: any) => (a.firstName < b.firstName ? -1 : a.firstName == b.firstName ? 0 : 1));
    }
  }
}
