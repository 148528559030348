























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import type { IRoleType } from "@/interfaces/IRoleType";
import type { IRole } from "@/interfaces/IRole";
import PermissionPanel from "@/components/Permission/PermissionPanel.vue";
import type { IPermission } from "@/interfaces/IPermission";
import FscCard from "@/components/Card/FscCard.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

const RoleTypeModule = namespace("role-type");

@Component({
  components: {
    FscSimpleCard,
    FscCard,
    PermissionPanel,
    Validation,
    SaveButton,
    AbortButton,
    FormCard,
  },
})
export default class RoleForm extends mixins(CompanyMixin) {
  public name = "RoleForm";

  @Prop()
  public data!: IRole;

  @Prop({ type: Boolean, default: () => false })
  public isLoading!: boolean;

  public role = "";
  public description = "";
  public roleType: number | undefined = 0;

  @RoleTypeModule.Action("findAll")
  public roleTypeFindAll: any;

  @RoleTypeModule.Getter("getDataList")
  public roleTypes: any;

  public selectedNotModulePermission: Array<IPermission> = [];
  public selectedModulePermission: Array<IPermission> = [];

  public onAbort(): void {
    this.$emit("abort");
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    this.$emit("on-submit", {
      name: this.role,
      description: this.description,
      roleType: this.roleType,
      permissions: this.selectedNotModulePermission.concat(this.selectedModulePermission),
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      role: { required },
      description: { required },
      roleType: { required },
    };
  }

  @Watch("data", { deep: true, immediate: true })
  public initData(data: IRole): void {
    if (Object.keys(data).length !== 0) {
      this.role = data.name;
      this.description = data.description;
      this.roleType = data.roleType.id;
    }
  }

  public get roleTypeOption(): Array<any> {
    return this.roleTypes.map((roleType: IRoleType) => ({
      text: roleType.name,
      value: roleType.id,
    }));
  }

  public mounted(): void {
    this.roleTypeFindAll({
      resource: "role-types",
      descriptionField: "name",
    });
  }

  public selectedNotModulePermissions(val: Array<IPermission>): void {
    this.selectedNotModulePermission = val;
  }

  public selectedModulePermissions(val: Array<IPermission>): void {
    this.selectedModulePermission = val;
  }
}
