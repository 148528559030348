








import { Component, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard },
})
export default class FormView extends Vue {
  public name = "FormView";

  public closeView(): void {
    this.$emit("close");
  }
}
