





































import { Component, Watch } from "vue-property-decorator";
import Actions from "@/components/Actions.vue";
import Table from "@/components/Table.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { namespace } from "vuex-class";
import RoleView from "./RoleView.vue";
import RoleForm from "./RoleForm.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import CompanyMixin from "@/mixins/CompanyMixin";

const RoleModule = namespace("role");

@Component({
  components: {
    FscPageHeader,
    FscSimpleCard,
    FscCard,
    RemoveButton,
    RoleForm,
    RoleView,
    EditButton,
    CreateButton,
    Table,
    Actions,
  },
})
export default class Roles extends mixins(ViewSwitchMixin, CompanyMixin) {
  public name = "Roles";

  public fields = [
    {
      key: "name",
      label: this.$t("roles.role"),
      sortable: false,
    },
    {
      key: "description",
      label: this.$t("roles.description"),
      sortable: false,
      class: "text-center",
    },
    {
      key: "roleType.name",
      label: this.$t("roles.role_type"),
      sortable: false,
    },
  ];

  @RoleModule.Action("findAll")
  public findAllAction: any;

  @RoleModule.Getter("getDataList")
  public items: any;

  @RoleModule.Getter("getTotal")
  public totalRows: any;

  @RoleModule.Action("create")
  public create: any;

  @RoleModule.Action("update")
  public update: any;

  @RoleModule.Action("getSuccess")
  public success!: boolean;

  @RoleModule.Action("findOne")
  public findOne: any;

  @RoleModule.Getter("getDataItem")
  public item: any;

  @RoleModule.Getter("getIsLoading")
  public getIsLoading: any;

  public roles: any = [];

  protected query: Record<any, any> | null = null;

  public requestQuery(query: Record<any, any>): void {
    this.query = query;
    this.findAllAction({ resource: "roles", filter: query });
  }

  public async onRowClicked(ctx: any): Promise<void> {
    this.openView();
    this.selectedRow = ctx;
  }

  public async onSubmit(item: any): Promise<void> {
    if (this.typeCreate()) {
      await this.create({
        data: item,
        resource: "roles",
        descriptionField: "name",
      });
    } else {
      await this.update({
        id: this.selectedRow.id,
        data: { id: this.selectedRow.id, ...item },
        resource: "roles",
        descriptionField: "name",
      });
    }

    if (this.success && this.query) {
      this.requestQuery(this.query);
    }
  }

  public get dataItem(): any {
    return this.typeCreate() ? {} : this.item;
  }

  @Watch("items", { deep: true, immediate: true })
  public onRolesLoad(roles: any) {
    if (roles) {
      this.roles = roles.filter((role: any) => {
        if (role.roleType && role.roleType.id !== 3) {
          return role;
        }
      });
    }
  }
}
