























import { Component, Prop, Vue } from "vue-property-decorator";
import PermissionTable from "@/components/Permission/PermissionTable.vue";
import { namespace } from "vuex-class";
import type { IPermission } from "@/interfaces/IPermission";

const PermissionModule = namespace("permission");

@Component({
  components: { PermissionTable },
})
export default class PermissionPanel extends Vue {
  public name = "PermissionPanel";

  @PermissionModule.Action("findAll")
  public permissionFindAll: any;

  @PermissionModule.Getter("getDataList")
  public permissions: any;

  @Prop({ default: () => [] })
  public data!: Array<IPermission>;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;

  public async mounted(): Promise<void> {
    await this.permissionFindAll({
      resource: "permissions",
    });
  }

  public get notModulePermissions(): Array<IPermission> {
    return this.permissions.filter((x: IPermission) => !x.modulePermission);
  }

  public get modulePermissions(): Array<IPermission> {
    return this.permissions.filter((x: IPermission) => x.modulePermission);
  }

  public get selectedNotModulePermissions(): Array<IPermission> {
    return this.data.filter((x: IPermission) => !x.modulePermission);
  }

  public get selectedModulePermissions(): Array<IPermission> {
    return this.data.filter((x: IPermission) => x.modulePermission);
  }

  public selectedNotModulePermissionsHandle(val: Array<IPermission>): void {
    this.$emit("selected-not-module-permissions", val);
  }

  public selectedModulePermissionsHandle(val: Array<IPermission>): void {
    this.$emit("selected-module-permissions", val);
  }
}
